import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Pinterest = (props) => {
  return (
    <Icon height={props.height} viewBox={props.viewBox} width={props.width}>
      <path
        d="M14 0C6.2685 0 0 6.2685 0 14C0 19.9313 3.69017 24.997 8.89817 27.0363C8.77567 25.9292 8.66483 24.2293 8.94717 23.0207C9.2015 21.9287 10.5887 16.0615 10.5887 16.0615C10.5887 16.0615 10.1698 15.2227 10.1698 13.9837C10.1698 12.0365 11.298 10.584 12.7027 10.584C13.8973 10.584 14.4737 11.4812 14.4737 12.5557C14.4737 13.7573 13.7095 15.5528 13.314 17.2165C12.9838 18.6107 14.0128 19.747 15.3872 19.747C17.8757 19.747 19.7878 17.1232 19.7878 13.3362C19.7878 9.98433 17.3798 7.6405 13.9405 7.6405C9.9575 7.6405 7.6195 10.6283 7.6195 13.7165C7.6195 14.9193 8.08267 16.2097 8.66133 16.912C8.71076 16.9648 8.74566 17.0296 8.76263 17.0999C8.7796 17.1702 8.77806 17.2438 8.75817 17.3133C8.652 17.7555 8.41633 18.7063 8.36967 18.9012C8.309 19.1578 8.16667 19.2115 7.90183 19.0878C6.153 18.2735 5.05983 15.7173 5.05983 13.664C5.05983 9.24817 8.26817 5.19167 14.3103 5.19167C19.1672 5.19167 22.9413 8.652 22.9413 13.2778C22.9413 18.1032 19.8987 21.9858 15.6765 21.9858C14.2578 21.9858 12.9243 21.2485 12.4682 20.3782C12.4682 20.3782 11.7658 23.051 11.5955 23.7067C11.2793 24.9223 10.4265 26.4472 9.85483 27.377C11.165 27.7807 12.5568 28 14 28C21.7315 28 28 21.7315 28 14C28 6.2685 21.7315 0 14 0Z"
        fill="#B1B5BD"
      />
    </Icon>
  );
};

Pinterest.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string
};

Pinterest.defaultProps = {
  height: '28',
  viewBox: '0 0 28 28',
  width: '28'
};

export default Pinterest;
