import React from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { trackHeaderClick } from 'shared/utils/tracking-utils/header';

import styles from './HeaderNavItem.module.scss';

const HeaderNavItem = (props) => {
  const router = useRouter();
  const { href, label, trackingName } = props;
  const isActive = router.pathname === href;

  const handleTracking = () => {
    if (trackingName && trackingName.length) {
      trackHeaderClick(trackingName);
    }
  };

  return (
    <li
      className={cx(styles.headerNavItem, {
        [styles.isActiveNavItem]: isActive
      })}
      key={href}
      id={`header-nav-${label.toLowerCase().replace(' ', '-')}`}
    >
      <a href={href} onClick={handleTracking}>
        {label}
      </a>
    </li>
  );
};
HeaderNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  trackingName: PropTypes.string.isRequired
};

export default HeaderNavItem;
