const headerNavigationItems = [
  {
    href: '/pricing',
    label: 'Plans',
    trackingName: 'Pricing'
  },
  {
    href: '/on-the-menu',
    label: 'On The Menu',
    trackingName: 'On The Menu'
  },
  {
    href: '/market',
    label: 'Market',
    trackingName: 'Shop'
  },
  {
    href: '/gifts',
    label: 'Gift Cards',
    trackingName: 'Gifts'
  },
  {
    href: 'https://blog.blueapron.com/',
    label: 'Blog',
    trackingName: 'Blog'
  }
];

export default headerNavigationItems;
