import React from 'react';

const LegalDisclaimer = () => {
  return (
    <div className="pom-LegalDisclaimer">
      <p>
        If you are using a screen reader and are having problems using this website, please call
        <a href="tel:+18444628299">1-844-462-8299</a>
        for assistance.
      </p>
    </div>
  );
};

export default LegalDisclaimer;
