import React from 'react';
import { string } from 'prop-types';
import Link from '../Link/Link';

const GooglePlayStoreLink = (props) => {
  return (
    <Link to={props.href} className="pom-GooglePlayStoreLink" aria-label="Google Play Store">
      <div className="pom-GooglePlayStoreImg" />
    </Link>
  );
};

GooglePlayStoreLink.propTypes = {
  href: string
};

GooglePlayStoreLink.defaultProps = {
  href: 'https://play.google.com/store/apps/details?id=com.blueapron.blueapron.release'
};

export default GooglePlayStoreLink;
