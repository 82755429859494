import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';

export default class Link extends React.PureComponent {
  render() {
    const { to, external, children, ...rest } = this.props;

    if (external) {
      return (
        <a href={to} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <NextLink href={to} {...rest}>
        {children}
      </NextLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

Link.defaultProps = {
  external: true
};
