import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isString from 'lodash/isString';

import { FlashMessage, FlashMessageReact } from 'components/common/FlashMessage';

import { initializeNotices } from 'services/notices/notices';
import { removeNotice, DISPLAY_METHODS } from 'redux/reducers/notices';
import scrollToElement from 'shared/utils/scroll-to-element/scroll-to-element';

import { fixedContainer } from './NoticesContainer.module.scss';

class NoticesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.removeNotice = this.removeNotice.bind(this);
  }

  componentDidMount() {
    initializeNotices();
  }

  componentDidUpdate() {
    if (this.shouldScroll()) scrollToElement(this.element);
  }

  removeNotice(key) {
    this.props.removeNotice(key);
  }

  displayComponent(key, notice) {
    return isString(notice.message) ? (
      <FlashMessage
        multiline={notice.multiline}
        message={notice.message}
        level={notice.type}
        noticeKey={key}
        removeNotice={this.removeNotice}
      />
    ) : (
      <FlashMessageReact
        multiline={notice.multiline}
        message={notice.message}
        level={notice.type}
        noticeKey={key}
        removeNotice={this.removeNotice}
      />
    );
  }

  displayFlashMsg(key, notice) {
    return this.displayComponent(key, notice);
  }

  displayMessage(key, notice) {
    // below will render it 2 times, once to reserve space, and second time to display fixed content.
    // On the smaller screens fixed content will be just hidden, so it will scroll together with content
    // same as header. This will appear as if it is attached to below the header.
    // Double-render is needed because height of the notice may vary dependent on the content.
    return (
      <Fragment key={`link-click-${key}`}>
        {this.props.isSticky && <div className={fixedContainer}>{this.displayFlashMsg(key, notice)}</div>}
        {this.displayFlashMsg(key, notice)}
      </Fragment>
    );
  }

  displayNotices() {
    const noticesToDisplay = Object.entries(this.props.notices).reduce((acc, noticeEl) => {
      const [, notice] = noticeEl;
      if (notice.displayMethod !== DISPLAY_METHODS.FLASH) {
        return acc;
      }
      return {
        ...acc,
        [notice.level]: noticeEl // Always display the last notice, display only one notice of each level
      };
    }, {});

    return Object.entries(noticesToDisplay)
      .sort((a, b) => a[0] - b[0])
      .map(([, noticeToDisplay]) => this.displayMessage(...noticeToDisplay));
  }

  hasNotice() {
    return Boolean(this.props.notices && Object.keys(this.props.notices).length);
  }

  shouldScroll() {
    return this.hasNotice() && this.props.shouldScroll;
  }

  render() {
    return (
      <span
        ref={(element) => {
          this.element = element;
        }}
      >
        {this.hasNotice() && this.displayNotices()}
      </span>
    );
  }
}

NoticesContainer.propTypes = {
  removeNotice: PropTypes.func.isRequired,
  shouldScroll: PropTypes.bool,
  notices: PropTypes.shape({
    key: PropTypes.string,
    multiline: PropTypes.bool,
    payload: PropTypes.shape({
      type: PropTypes.oneOf(['success', 'error', 'info']),
      message: PropTypes.string,
      displayMethod: PropTypes.string
    })
  }),
  isSticky: PropTypes.bool
};

NoticesContainer.defaultProps = {
  notices: undefined,
  shouldScroll: false,
  isSticky: true
};

export { NoticesContainer as Component };

export const mapStateToProps = (state) => {
  return {
    notices: state?.notices?.byId,
    shouldScroll: state?.notices?.scroll
  };
};

export const mapDispatchToProps = {
  removeNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticesContainer);
