import React from 'react';
import PropTypes from 'prop-types';

import colors from 'shared/utils/colors';
import Icon from '../Icon';

const ExMark = (props) => {
  return (
    <Icon height={props.height} width={props.width} viewBox={props.viewBox}>
      <g fill="none" stroke={colors(props.color)} strokeWidth="2" transform="translate(1)">
        {props.bold && (
          <g strokeWidth="4" stroke={colors(props.color)} strokeLinecap="round" transform="translate(9, 10)">
            <path d="M0,0 L11.4357121,11.4357121" />
            <path d="M11.4357121,0 L0,11.4357121" />
          </g>
        )}
        {!props.bold && <path d="M11 11l8.4 8.4M11 19.4l8.4-8.4" strokeLinecap="round" />}
        {!props.hideOutline && <ellipse cx="15" cy="16" rx="15" ry="15" />}
      </g>
    </Icon>
  );
};

ExMark.propTypes = {
  color: PropTypes.oneOf(['blue', 'green', 'yellow', 'red', 'orange', 'gray', 'white']),
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  hideOutline: PropTypes.bool,
  bold: PropTypes.bool
};

ExMark.defaultProps = {
  color: 'red',
  height: '32',
  width: '32',
  viewBox: '0 0 32 32',
  hideOutline: false,
  bold: false
};

export default ExMark;
