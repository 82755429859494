import React from 'react';

import Facebook from '../icons/Facebook/Facebook';
import Instagram from '../icons/Instagram/Instagram';
import Pinterest from '../icons/Pinterest/Pinterest';
import TikTok from '../icons/TikTok/TikTok';
import Youtube from '../icons/Youtube/Youtube';
import Link from '../Link/Link';

const FollowUs = () => {
  const socials = [
    {
      name: 'Instagram',
      component: <Instagram />,
      link: 'https://www.instagram.com/blueapron/'
    },
    {
      name: 'TikTok',
      component: <TikTok />,
      link: 'https://www.tiktok.com/@blueapron?lang=en'
    },
    {
      name: 'Facebook',
      component: <Facebook />,
      link: 'https://www.facebook.com/BlueApron/'
    },
    {
      name: 'Pinterest',
      component: <Pinterest />,
      link: 'https://www.pinterest.com/blueapron/'
    },
    {
      name: 'Youtube',
      component: <Youtube />,
      link: 'https://www.youtube.com/@Blueapron'
    }
  ];

  return (
    <div className="pom-FollowUs">
      <h3 className="pom-FollowUsHeader">Follow Us</h3>
      <ul>
        {socials.map(({ component, link, name }) => (
          <li className={`pom-${name}`} key={name}>
            <Link to={link} aria-label={`${name} link`} external={true}>
              {component}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FollowUs;
