import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import { bool, func, string } from 'prop-types';

import { screenMdMin, screenMdMax } from 'shared/constants/breakpoints';
import {
  fetchUser,
  getUserFirstName,
  isUserAuthenticated as isUserAuthenticatedSelector,
  isUserActive as isUserActiveSelector
} from 'redux/reducers/user';
import cx from 'classnames';
import AccountDropdown from '../AccountDropdown';
import HeaderNav from '../HeaderNav';
import LoginLink from '../LoginLink';
import SignUpButton from '../SignUpButton';
import activeHeaderNavigationItems from './activeHeaderNavItems';
import headerNavigationItems from '../HeaderNav/headerNavItems';

import styles from './HeaderContent.module.scss';

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false
});

const HeaderContent = ({ reduxFetchUser, firstName, isUserAuthenticated, isUserActive }) => {
  useEffect(() => {
    const populateUser = async () => {
      await reduxFetchUser();
    };
    populateUser();
  }, []);

  const navItems = isUserActive ? activeHeaderNavigationItems : headerNavigationItems;
  const mobileNavItems = [{ href: '', label: 'MENU' }, { href: '/', label: 'Home' }, ...navItems];

  return (
    <div className={styles.container} id="header-container">
      <MediaQuery minWidth={screenMdMin}>
        <div className={styles.header}>
          <HeaderNav headerItems={navItems} />
          {isUserActive ? (
            <AccountDropdown firstName={firstName} isExploded={false} />
          ) : (
            <>
              <LoginLink isReturningGuest={isUserAuthenticated} />
              <SignUpButton id="sign-up-topnav" />
            </>
          )}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={screenMdMax}>
        <div>
          <HeaderNav headerItems={mobileNavItems} />
          {isUserActive ? (
            <AccountDropdown firstName={firstName} isExploded={true} />
          ) : (
            <>
              <SignUpButton className={styles.headerButtonContainer} size="full" id="sign-up-sidebar" />
              <LoginLink
                className={cx(styles.headerButtonContainer, styles.loginLink)}
                isReturningGuest={isUserAuthenticated}
                isButton={true}
              />
            </>
          )}
        </div>
      </MediaQuery>
    </div>
  );
};

HeaderContent.propTypes = {
  reduxFetchUser: func.isRequired,
  firstName: string.isRequired,
  isUserAuthenticated: bool.isRequired,
  isUserActive: bool.isRequired
};

const mapStateToProps = (state) => ({
  firstName: getUserFirstName(state) || 'Chef',
  isUserAuthenticated: isUserAuthenticatedSelector(state),
  isUserActive: isUserActiveSelector(state)
});

const mapDispatchToProps = {
  reduxFetchUser: fetchUser
};

export { HeaderContent as Component };

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContent);
