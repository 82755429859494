/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { email as emailValidator } from 'shared/utils/validators';
import { bool, func, string } from 'prop-types';
import Form from '../Form/Form';
import Input from '../Input/Input';
import Button from '../Button/Button';

const NewsletterSignUp = ({ onInputChange, showForm, onSubmit, emailErrorMessage }) => {
  const title = `Discover what's cookin'`;
  return (
    <div className="pom-NewsletterSignUp">
      <h3 className="pom-NewsletterSignUpHeader">{title}</h3>
      {showForm && (
        <Form onSubmit={onSubmit} onInputChange={onInputChange}>
          {(onChange) => (
            <div>
              <input
                type="hidden"
                name="newsletter_subscriber[source]"
                id="newsletter_subscriber_source"
                value="Footer newsletter form"
                autoComplete="off"
              />
              <input
                type="hidden"
                name="newsletter_subscriber[receive_meat_fish]"
                id="newsletter_subscriber_receive_meat_fish"
                value="1"
                autoComplete="off"
              />
              <div className="pom-NewsletterInput">
                <Input
                  name="email"
                  styles={{
                    'margin-top': 0,
                    height: '40px',
                    padding: '10px 15px',
                    'border-radius': '4px 0 0 4px'
                  }}
                  validator={emailValidator}
                  onChange={onChange}
                  showErrorText={!!emailErrorMessage}
                  errorMessage={emailErrorMessage}
                />
                <div className="pom-NewsletterSignUp--actions">
                  <Button text="Go" htmlType="submit" buttonType="input-end" />
                </div>
              </div>
              <p>
                Sign up for offers, recipes, news, & more (subscribers to the Blue Apron recipe newsletter agree to our
                Privacy Policy)
              </p>
            </div>
          )}
        </Form>
      )}
    </div>
  );
};

NewsletterSignUp.propTypes = {
  showForm: bool,
  onSubmit: func,
  onInputChange: func,
  emailErrorMessage: string || null
};

NewsletterSignUp.defaultProps = {
  showForm: false,
  onSubmit() {},
  onInputChange() {},
  emailErrorMessage: null
};

export default NewsletterSignUp;
