import React from 'react';
import queryString from 'query-string';
import { decode } from 'he';
import Image from '../Image/Image';
import Link from '../Link/Link';
import getBlogData from './getBlogData';

class BlogPreview extends React.Component {
  constructor(props) {
    super(props);

    this.parseAndSetBlog.bind(this);

    this.state = {
      // Set default values
      blogItem: {
        image:
          'https://i1.wp.com/blog.blueapron.com/wp-content/uploads/2020/04/IMG_0487.jpg?resize=150%2C150&#038;ssl=1',
        title: 'A Guide to Baking with Frozen Berries',
        link: 'https://blog.blueapron.com/a-guide-to-baking-with-frozen-berries'
      }
    };
  }

  async componentDidMount() {
    try {
      const blogData = await getBlogData();
      this.parseAndSetBlog(blogData);
    } catch (error) {
      console.log(error);
    }
  }

  parseAndSetBlog(blogResponseDoc) {
    const blogItem = blogResponseDoc.getElementsByTagName('item')[0];
    if (blogItem) {
      const title = (blogItem.getElementsByTagName('title')[0] || {}).innerHTML;
      const link = (blogItem.getElementsByTagName('link')[0] || {}).innerHTML;
      const image = (blogItem.getElementsByTagName('media:content')[0] || {}).getAttribute('url');
      this.setState({
        blogItem: {
          image,
          title,
          link
        }
      });
    }
  }

  render() {
    const {
      blogItem: { image, title, link }
    } = this.state;

    const parsedImageUrl = queryString.parseUrl(image);
    const imagePreviewUrl = `${parsedImageUrl.url}?${queryString.stringify({
      ...parsedImageUrl.query,
      fit: '70,70'
    })}`;

    return (
      <div className="pom-BlogPreview">
        <h3 className="pom-BlogHeader">
          <Link to="http://blog.blueapron.com/" aria-label="Blog Header" external={true}>
            From the blog
          </Link>
        </h3>
        <div className="pom-BlogContent">
          <Image src={imagePreviewUrl} alt="Latest Blog Image" />
          <span className="pom-LatestBlogTitle">
            <Link to={link} aria-label="Latest Blog Title" external={true}>
              {decode(title)}
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

export default BlogPreview;
