import React, { useState } from 'react';
import Hamburger from 'components/common/icons/Hamburger/Hamburger';
import styles from './MobileHeaderButton.module.scss';
import MobileHeaderNav from '../MobileHeaderNav';

const MobileHeaderButton = () => {
  const [showMobileHeaderNav, setShowMobileHeaderNav] = useState(false);

  const handleMobileHeaderButtonClick = () => {
    setShowMobileHeaderNav(true);
  };

  const handleCloseNav = () => {
    setShowMobileHeaderNav(false);
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.mobileHeaderButton}
        onClick={handleMobileHeaderButtonClick}
        aria-label="Mobile Header Button"
      >
        <Hamburger />
      </button>
      {showMobileHeaderNav && <MobileHeaderNav onHandleCloseNav={handleCloseNav} />}
    </div>
  );
};

export default MobileHeaderButton;
