import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Caret from 'components/common/icons/Caret/Caret';
import Link from 'components/common/Link';

import { logoutUser, hasAnyPastOrder as hasAnyPastOrderState } from 'redux/reducers/user';
import { deleteSessions } from 'services/api/user';
import reportToRollbar from 'shared/utils/error-handling/report-to-rollbar/report-to-rollbar';
import { trackLogout } from 'shared/utils/tracking-utils/header';

import styles from './AccountDropdown.module.scss';

const AccountDropdown = ({ firstName, isExploded, hasAnyPastOrder, reduxLogoutUser }) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const handleOpenDropdown = () => setShowAccountDropdown(true);

  const handleCloseDropdown = () => setShowAccountDropdown(false);

  const handleSignout = async () => {
    try {
      await deleteSessions();
      reduxLogoutUser();
      trackLogout();
      window.location.assign('/users/sign_in');
    } catch (error) {
      reportToRollbar(error);
    }
  };

  const dropdownContents = (
    <div className={styles.accountDropdownMenu}>
      <ul>
        <li>
          <Link to="/account/settings">Account Settings</Link>
        </li>
        {hasAnyPastOrder && (
          <li>
            <Link to="/account/deliveryHistory">Delivery History</Link>
          </li>
        )}
        <li>
          <Link to="/account/recycle">How To Recycle</Link>
        </li>
        <li>
          <Link to="https://support.blueapron.com/" target="_blank" aria-describedby="new-tab">
            Help Center
          </Link>
        </li>
        <li className={styles.navLogout}>
          <a onClick={handleSignout} data-tracking-name="Log Out" role="button" tabIndex={0} aria-label="Log Out">
            Log Out
          </a>
        </li>
      </ul>
    </div>
  );

  if (isExploded) {
    return dropdownContents;
  }

  return (
    <div
      className={styles.accountDropdownButton}
      tabIndex={0}
      role="menu"
      onMouseEnter={handleOpenDropdown}
      onMouseLeave={handleCloseDropdown}
      aria-label="Account Options Header Menu"
      aria-haspopup={true}
      aria-expanded={showAccountDropdown}
    >
      <span>
        {`${firstName} `}
        <Caret containerClassNames={styles.accountDropdownIcon} />
      </span>
      {showAccountDropdown && dropdownContents}
    </div>
  );
};

AccountDropdown.propTypes = {
  firstName: PropTypes.string,
  isExploded: PropTypes.bool,
  hasAnyPastOrder: PropTypes.bool,
  reduxLogoutUser: PropTypes.func.isRequired
};

AccountDropdown.defaultProps = {
  firstName: 'Chef',
  isExploded: false,
  hasAnyPastOrder: false
};

const mapStateToPropsSelector = createSelector(hasAnyPastOrderState, (hasAnyPastOrder) => ({ hasAnyPastOrder }));

const mapDispatchToProps = {
  reduxLogoutUser: logoutUser
};

export { AccountDropdown as Component };

export default connect(mapStateToPropsSelector, mapDispatchToProps)(AccountDropdown);
