import React from 'react';
import PropTypes from 'prop-types';
import GooglePlayStoreLink from '../GooglePlayStoreLink/GooglePlayStoreLink';
import AppleStoreLink from '../AppleStoreLink/AppleStoreLink';
import LegalDisclaimer from './LegalDisclaimer';
import FooterLink from './FooterLink';
import {
  footerLinks as defaultFooterLinks,
  customerSupportLinks as defaultCustomerSupportLinks,
  privacyAndTermsLinks as defaultPrivacyAndTermsLinks
} from './footerLinks';

const Footer = (props) => {
  const { footerLinks, customerSupportLinks, privacyAndTermsLinks } = props;
  const year = new Date().getFullYear();

  return (
    <div className="pom-FooterNav">
      <ul className="pom-FooterNavList">
        <FooterLink links={footerLinks} className="pom-FooterNavItem" />
        <FooterLink links={customerSupportLinks} title="Customer Support" className="pom-navLink-Column" />
        <FooterLink
          links={privacyAndTermsLinks}
          title={`${String.fromCharCode(169)} Blue Apron, LLC ${year}`}
          className="pom-navLink-Column pom-privacyAndTerms"
        >
          <li className="pom-AppStoreLink">
            <AppleStoreLink />
          </li>
          <li className="pom-AppStoreLink">
            <GooglePlayStoreLink />
          </li>
        </FooterLink>
      </ul>
      <LegalDisclaimer />
    </div>
  );
};

const linkPropType = {
  id: PropTypes.number,
  href: PropTypes.string,
  label: PropTypes.string
};

Footer.propTypes = {
  footerLinks: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
  customerSupportLinks: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
  privacyAndTermsLinks: PropTypes.arrayOf(PropTypes.shape(linkPropType))
};

Footer.defaultProps = {
  footerLinks: defaultFooterLinks,
  customerSupportLinks: defaultCustomerSupportLinks,
  privacyAndTermsLinks: defaultPrivacyAndTermsLinks
};

export default Footer;
