import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ExMark from '../icons/ExMark/ExMark';

// eslint-disable-next-line react/display-name
const FlashMessageReact = forwardRef(({ message, level, noticeKey, removeNotice, hideCloseButton, multiline }, ref) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    removeNotice(noticeKey);
    setIsClosing(true);
  };

  return (
    <div
      className={cx('pom-FlashMessage pom-FlashMessage__containerSlide', {
        'pom-FlashMessage--success': level === 'success',
        'pom-FlashMessage--warning': level === 'warning',
        'pom-FlashMessage--info': level === 'info',
        'pom-FlashMessage--error': level === 'error',
        'pom-FlashMessage--multiline': multiline,
        isClosing
      })}
    >
      <div className="pom-FlashMessage__textContainer">
        <span ref={ref} className="text">
          {message}
        </span>
      </div>
      {hideCloseButton ? null : (
        <button
          type="button"
          className={cx('pom-FlashMessage__button', {
            isClosing
          })}
          onClick={handleClose}
          aria-label="Close"
        >
          <ExMark color="white" hideOutline={true} height="48" width="48" />
        </button>
      )}
    </div>
  );
});

FlashMessageReact.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  level: PropTypes.oneOf(['success', 'info', 'error', 'warning']).isRequired,
  noticeKey: PropTypes.string.isRequired,
  removeNotice: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
  multiline: PropTypes.bool
};

FlashMessageReact.defaultProps = {
  hideCloseButton: false,
  multiline: false
};

export default FlashMessageReact;
