import React from 'react';
import dynamic from 'next/dynamic';
import LogoText from 'components/common/icons/LogoText/LogoText';
import Link from 'components/common/Link';

import cx from 'classnames';
import { onLogoClick } from 'shared/utils/tracking-utils/header';
import { screenMdMin, screenMdMax } from 'shared/constants/breakpoints';
import PropTypes from 'prop-types';
import HeaderContent from './HeaderContent';
import MobileHeaderButton from './MobileHeaderButton';

import styles from './Header.module.scss';

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false
});

const Header = ({ isSticky, couponPresent }) => {
  return (
    <>
      <div
        className={cx(styles.header, isSticky ? styles.sticky : styles.fixed, {
          [styles.couponPresent]: couponPresent
        })}
      >
        <div className={styles.container}>
          <Link className={styles.logo} to="/" external={true} onClick={onLogoClick} aria-label="Blue Apron Home">
            <LogoText />
          </Link>
          <MediaQuery minWidth={screenMdMin}>
            <HeaderContent />
          </MediaQuery>
          <MediaQuery maxWidth={screenMdMax}>
            <MobileHeaderButton />
          </MediaQuery>
        </div>
      </div>
      {!isSticky && <div className={styles.headerPlaceholder} />}
    </>
  );
};

Header.propTypes = {
  isSticky: PropTypes.bool,
  couponPresent: PropTypes.bool
};

Header.defaultProps = {
  isSticky: false,
  couponPresent: false
};

export default Header;
