import { ensureOnAccountApp } from 'shared/utils/navigation/navigation';

import { DISPLAY_METHODS, LEVELS } from 'redux/reducers/notices';

export const KEYS = {
  USER_CHURNED: 'user_churned',
  USER_ACTIVE_FOOD: 'user_active_food'
};

export const KEY_MESSAGE_MAP = {
  [KEYS.USER_CHURNED]: {
    ensureCondition: ensureOnAccountApp,
    message:
      'Looks like you already have an account, so we’ve logged you in. Follow steps below to reactivate your account.',
    level: LEVELS.INFO,
    displayMethod: DISPLAY_METHODS.FLASH
  },
  [KEYS.USER_ACTIVE_FOOD]: {
    ensureCondition: ensureOnAccountApp,
    message:
      'Looks like you already have an account, so we’ve logged you in. Review your account settings <a href="#settings">here</a>.',
    level: LEVELS.INFO,
    displayMethod: DISPLAY_METHODS.FLASH
  }
};

export default { KEY_MESSAGE_MAP, KEYS };
