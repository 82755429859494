export const footerLinks = [
  { id: 1, href: '/pages/sample-recipes', label: 'On the Menu' },
  { id: 2, href: '/pricing', label: 'Pricing' },
  { id: 3, href: '/pages/vision', label: 'Our Vision' },
  { id: 4, href: '/market', label: 'Market' },
  { id: 5, href: '/gifts', label: 'Gift Cards' },
  { id: 6, href: 'http://blog.blueapron.com', label: 'Blog' },
  { id: 7, href: '/cookbook', label: 'Cookbook' },
  { id: 8, href: '/pages/meet-our-recent-partners', label: 'Suppliers' },
  {
    id: 9,
    href: 'https://signup.cj.com/member/signup/publisher/?cid=5446852',
    label: 'Affiliates'
  },
  {
    id: 10,
    href: '/pages/supply-chain-disclosure',
    label: 'Supply Chains Act'
  },
  {
    id: 11,
    href: 'http://blog.blueapron.com/foodsafety/',
    label: 'Food Safety'
  },
  { id: 12, href: '/pages/our-team', label: 'Career' },
  { id: 13, href: 'mailto:press@blueapron.com', label: 'Press' },
  { id: 14, href: '/pages/our-team', label: 'Our Team' },
  {
    id: 15,
    href: 'https://cook.blueapron.com/heroes/',
    label: 'Military & Veterans'
  },
  {
    id: 16,
    href: 'https://cook.blueapron.com/heroes/?utm_content=Students110off',
    label: 'Students'
  },
  {
    id: 17,
    href: 'https://cook.blueapron.com/heroes/?utm_content=Graduates110off',
    label: 'Graduates'
  },
  { id: 18, href: 'https://cook.blueapron.com/heroes/', label: 'Teachers' },
  {
    id: 19,
    href: 'https://cook.blueapron.com/heroes/',
    label: 'Seniors (+55)'
  },
  {
    id: 20,
    href: 'https://cook.blueapron.com/heroes/',
    label: 'Medical Staff'
  },
  {
    id: 21,
    href: 'https://cook.blueapron.com/heroes/',
    label: 'First Responders'
  }
];

export const customerSupportLinks = [
  {
    id: 1,
    href: 'https://support.blueapron.com',
    label: 'Help Center & FAQ'
  },
  {
    id: 2,
    href: 'mailto:contact@blueapron.com',
    label: 'contact@blueapron.com'
  },
  {
    id: 3,
    href: 'tel:+16468914349',
    label: '(646) 891-4349'
  }
];

export const privacyAndTermsLinks = [
  {
    id: 1,
    href: '/pages/privacy#ccpa',
    label: 'Do Not Sell or Share My Info'
  },
  {
    id: 2,
    href: '/pages/privacy#california',
    label: 'Notice to California Residents'
  },
  {
    id: 3,
    href: '/pages/privacy#ccpa',
    label: 'Ad Preferences'
  },
  {
    id: 4,
    href: '/pages/privacy',
    label: 'Privacy'
  },
  {
    id: 5,
    href: '/pages/terms',
    label: 'Terms'
  }
];
