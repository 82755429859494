import React from 'react';
import PropTypes from 'prop-types';
import { headerNavItem } from 'shared/prop-types/header';
import HeaderNavItem from '../HeaderNavItem';
import headerNavigationItems from './headerNavItems';
import styles from './HeaderNav.module.scss';

const HeaderNav = (props) => {
  const { headerItems } = props;

  return (
    <nav className={styles.container}>
      <ul className={styles.linksList}>
        {headerItems.map((item) => {
          return <HeaderNavItem key={item.href} {...item} />;
        })}
      </ul>
    </nav>
  );
};

HeaderNav.propTypes = {
  headerItems: PropTypes.arrayOf(headerNavItem)
};

HeaderNav.defaultProps = {
  headerItems: headerNavigationItems
};

export { HeaderNav as Component };
export default HeaderNav;
