import React from 'react';
import { bool, func } from 'prop-types';
import FollowUs from './FollowUs';
import BlogPreview from './BlogPreview';
import NewsletterSignUp from './NewsletterSignUp';

const StayConnected = ({ showNewsletterSignup, onSubmit, onInputChange }) => {
  return (
    <div className="pom-StayConnected">
      <FollowUs />
      {showNewsletterSignup && (
        <>
          <div className="pom-Divider" />
          <NewsletterSignUp showForm={showNewsletterSignup} onSubmit={onSubmit} onInputChange={onInputChange} />
        </>
      )}
      <div className="pom-Divider" />
      <BlogPreview />
    </div>
  );
};

StayConnected.propTypes = {
  showNewsletterSignup: bool,
  onInputChange: func,
  onSubmit: func
};

StayConnected.defaultProps = {
  showNewsletterSignup: false,
  onSubmit() {},
  onInputChange() {}
};

export default StayConnected;
