const colorMap = {
  blue: {
    100: '#c7dcfc',
    200: '#a1bce6',
    300: '#6b96d6',
    400: '#2e62af',
    500: '#245091',
    base: '#0f346c',
    600: '#0f346c',
    700: '#0d2a54'
  },
  green: {
    100: '#1eb389',
    base: '#00a979',
    200: '#00a979',
    300: '#00996e',
    400: '#008761'
  },
  red: {
    100: '#fa5768',
    base: '#e64c53',
    200: '#e64c53',
    300: '#d64148',
    400: '#ba272e'
  },
  orange: {
    100: '#ff8142',
    base: '#f26c29',
    200: '#f26c29',
    300: '#e65710',
    400: '#d34600'
  },
  yellow: {
    100: '#ffcb47',
    base: '#f1b828',
    200: '#f1b828',
    300: '#f0ac00',
    400: '#e09900'
  },
  gray: {
    100: '#fafbfc',
    200: '#f5f6f8',
    300: '#e4e6eb',
    400: '#d3d5db',
    base: '#b1b5bd',
    500: '#b1b5bd',
    600: '#6a6d75',
    700: '#303236'
  },
  white: {
    base: '#fff'
  },
  none: {
    base: '#fff'
  }
};

const colors = (color, tone = 'base') => {
  try {
    return colorMap[color][tone];
  } catch (error) {
    throw new Error('Invalid color and/or tone combination');
  }
};

export default colors;
