const accountAppPathname = '/account';

export const innerUtils = {
  isOnPathname: (pathname) => {
    // For http://localhost:3000/something/something#dark_side
    // the pathname is just /something/something
    return window.location.pathname === pathname;
  },
  redirectToPath: (path) => {
    window.location = path;
  }
};

export function ensureOnAccountApp() {
  if (innerUtils.isOnPathname(accountAppPathname)) {
    return true;
  }
  innerUtils.redirectToPath(accountAppPathname);
  return false;
}

export const getCurrentRouteSection = (path) => {
  const locationHashItems = (path || window.location.href).split('#');
  if (locationHashItems.length > 1) {
    const currentLocationSectionItems = locationHashItems.pop().split('/');
    return (
      currentLocationSectionItems[0] ||
      (currentLocationSectionItems.length > 1 ? currentLocationSectionItems[1] : locationHashItems[0].split('/').pop())
    );
  }
  return locationHashItems[0].split('/').pop();
};

export const isExternalLink = (href) =>
  !href
    .replace(/http(s{0,1}):\/\//gi, '')
    .split('/')[0]
    .includes(window.location.host);
