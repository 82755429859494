import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

const FooterLink = (props) => {
  const { title, links, className, children } = props;
  return (
    <li className={className}>
      {title && <h2 className="pom-navTitle">{title}</h2>}
      <ul className="pom-navLink">
        {links.map((link) => (
          <li className="pom-navLinkItem" key={link.id}>
            <Link to={link.href} aria-label={link.label}>
              {link.label}
            </Link>
          </li>
        ))}
        {children}
      </ul>
    </li>
  );
};

const linkPropType = {
  id: PropTypes.number,
  href: PropTypes.string,
  label: PropTypes.string
};

FooterLink.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

FooterLink.defaultProps = {
  title: '',
  links: [],
  className: '',
  children: undefined
};

export default FooterLink;
