const activeHeaderNavigationItems = [
  {
    href: '/account/current',
    label: 'Current',
    trackingName: 'Current'
  },
  {
    href: '/account/upcoming',
    label: 'Upcoming',
    trackingName: 'Upcoming'
  },
  {
    href: '/gifts',
    label: 'Gifts',
    trackingName: 'Gifts'
  },
  {
    href: '/market',
    label: 'Market',
    trackingName: 'Shop'
  }
];

export default activeHeaderNavigationItems;
