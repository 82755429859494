import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Hamburger = (props) => {
  return (
    <Icon height={props.height} viewBox={props.viewBox} width={props.width}>
      <path
        d="M560 38a2 2 0 0 1 2.001-2H606a2 2 0 1 1 0 4h-44a1.996 1.996 0 0 1-2-2zm0 14a2 2 0 0 1 2.001-2H606a2 2 0 1 1 0 4h-44a1.996 1.996 0 0 1-2-2zm0 14a2 2 0 0 1 2.001-2H606a2 2 0 1 1 0 4h-44a1.996 1.996 0 0 1-2-2z"
        fill="#737373"
        fillRule="evenodd"
      />
    </Icon>
  );
};

Hamburger.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string
};

Hamburger.defaultProps = {
  height: '16',
  viewBox: '560 36 48 32',
  width: '24'
};

export default Hamburger;
