import PropTypes from 'prop-types';

export const stepNavItemPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isClickable: PropTypes.bool,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool
});

export const headerNavItem = PropTypes.shape({
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
});
