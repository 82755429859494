import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Facebook = (props) => {
  return (
    <Icon height={props.height} viewBox={props.viewBox} width={props.width}>
      <path
        d="M29.0856 14.0856C29.0856 6.30631 22.7792 0 15 0C7.22076 0 0.914444 6.30631 0.914444 14.0856C0.914444 21.1161 6.06532 26.9433 12.7991 28V18.1572H9.22273V14.0856H12.7991V10.9823C12.7991 7.45213 14.902 5.50218 18.1195 5.50218C19.6605 5.50218 21.2725 5.77728 21.2725 5.77728V9.24365H19.4963C17.7466 9.24365 17.2009 10.3294 17.2009 11.4433V14.0856H21.1074L20.4829 18.1572H17.2009V28C23.9347 26.9433 29.0856 21.1161 29.0856 14.0856Z"
        fill="#B1B5BD"
      />
    </Icon>
  );
};

Facebook.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string
};

Facebook.defaultProps = {
  height: '28',
  viewBox: '0 0 30 28',
  width: '30'
};

export default Facebook;
