import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Youtube = (props) => (
  <Icon height={props.height} viewBox={props.viewBox} width={props.width}>
    <path
      d="M14.7832 0.510498C18.4514 0.583862 21.9129 0.557185 25.361 0.924004C26.2547 1.01738 27.1484 1.13743 27.9154 1.70433C28.6891 2.2779 29.2493 3.00487 29.396 3.9586C29.6094 5.35251 29.8095 6.75309 29.8962 8.15367C30.0896 11.1082 30.0229 14.0695 29.6094 17.004C29.4894 17.851 29.3427 18.698 28.8024 19.4117C28.2022 20.212 27.4485 20.7922 26.4414 20.899C24.6407 21.0924 22.8399 21.2858 21.0325 21.3591C16.0971 21.5592 11.1617 21.5392 6.22633 21.2258C5.33262 21.1657 4.43225 21.0657 3.54521 20.899C1.97122 20.5988 0.930783 19.6251 0.623988 18.0644C0.350541 16.6772 0.197144 15.2499 0.103771 13.836C-0.0896427 10.8881 -0.022949 7.94691 0.390557 5.01903C0.530616 4.01195 0.73737 3.0182 1.47768 2.24455C2.03124 1.66431 2.67818 1.26414 3.47185 1.11075C5.32595 0.757267 7.20674 0.697244 9.08086 0.637219C11.0617 0.577194 13.0292 0.550515 14.7832 0.510498ZM12.0154 6.49965C12.0154 9.54091 12.0154 12.4955 12.0154 15.5301C14.6365 14.0161 17.2043 12.5355 19.832 11.0149C17.1976 9.48756 14.6365 8.01361 12.0154 6.49965Z"
      fill="#B1B5BD"
    />
  </Icon>
);

Youtube.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string
};

Youtube.defaultProps = {
  height: '22',
  viewBox: '0 0 30 22',
  width: '30'
};

export default Youtube;
