import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const TikTok = (props) => (
  <Icon height={props.height} viewBox={props.viewBox} width={props.width}>
    <path
      d="M9.49634 25.9374C9.04159 25.9374 8.58684 25.9374 8.13209 25.9374C8.0664 25.9222 8.00577 25.897 7.94008 25.8919C4.56481 25.5332 1.69482 22.9714 0.957117 19.6467C0.856061 19.192 0.820691 18.7221 0.755005 18.2572C0.755005 17.8884 0.755005 17.5145 0.755005 17.1456C0.770163 17.0799 0.790374 17.0142 0.80048 16.9485C0.901536 15.9633 1.1946 15.0386 1.63419 14.1594C3.14498 11.1379 6.54045 9.34921 9.89046 9.7989C10.1178 9.82922 10.2037 9.8949 10.1987 10.1374C10.1734 11.0823 10.1734 12.0221 10.1684 12.967C10.1633 13.5127 10.1684 14.0584 10.1684 14.6344C10.0117 14.599 9.89551 14.5687 9.78435 14.5434C7.21248 13.9523 4.87303 16.0593 5.29747 18.7322C5.52484 20.152 6.68193 21.395 8.10177 21.688C9.56708 21.9912 10.9819 21.4758 11.8712 20.253C12.1996 19.8033 12.4775 19.3233 12.4876 18.7322C12.4977 17.8227 12.5432 16.9132 12.5432 16.0037C12.5533 11.3147 12.5533 6.62578 12.5583 1.93177C12.5583 1.31028 12.5735 0.688797 12.5836 0.0622559C13.9832 0.0622559 15.3778 0.0622559 16.7774 0.0622559C16.7875 0.163311 16.7976 0.264366 16.8077 0.365421C16.9442 1.77008 17.3736 3.06864 18.3034 4.15498C19.5969 5.67586 21.335 6.26703 23.245 6.47925C23.245 7.92939 23.245 9.37447 23.245 10.8246C20.9712 10.764 18.8895 10.1021 16.9745 8.77825C16.9745 8.92983 16.9745 9.01067 16.9745 9.09657C16.9694 11.6027 16.9745 14.1089 16.9593 16.6201C16.9543 17.3679 16.9543 18.1208 16.8734 18.8635C16.5046 22.1125 14.0338 24.9016 10.8606 25.6847C10.4058 25.7959 9.95109 25.8565 9.49634 25.9374Z"
      fill="#B1B5BD"
    />
  </Icon>
);

TikTok.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string
};

TikTok.defaultProps = {
  height: '26',
  viewBox: '0 0 24 26',
  width: '24'
};

export default TikTok;
