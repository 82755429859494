import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CloseButton from 'components/common/CloseButton';

import HeaderContent from '../HeaderContent';
import styles from './MobileHeaderNav.module.scss';

const MobileHeaderNav = ({ onHandleCloseNav }) => {
  return (
    <div>
      <div className={styles.overlayContainer} onClick={onHandleCloseNav} />
      <div className={cx(styles.mobileNav)}>
        <CloseButton onClose={onHandleCloseNav} />
        <HeaderContent />
      </div>
    </div>
  );
};

MobileHeaderNav.propTypes = {
  onHandleCloseNav: PropTypes.func.isRequired
};

export default MobileHeaderNav;
