const getElement = (selector) => {
  return document.querySelector(selector);
};

const scrollTo = (element) => {
  if (!element) return;
  const top = element.offsetTop - 68 >= 0 ? element.offsetTop - 68 : 0;
  window.scrollTo({ top, behavior: 'smooth' });
};

const scrollToElement = (elementOrSelector) => {
  elementOrSelector instanceof Element ? scrollTo(elementOrSelector) : scrollTo(getElement(elementOrSelector));
};

export default scrollToElement;
