import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'components/common/Button';

import { trackHeaderClick } from 'shared/utils/tracking-utils/header';

import styles from './LoginLink.module.scss';

const LoginLink = ({ className, isReturningGuest, isButton }) => {
  const onLogInClick = () => {
    trackHeaderClick('Log In');
    window.location.assign('/users/sign_in');
  };

  const loginElement = isButton ? (
    <Button
      buttonType="outline"
      size="full"
      text="log in"
      onClick={onLogInClick}
      styles={{ height: '40px', fontSize: '14px', fontWeight: 500 }}
    />
  ) : (
    <a href="/users/sign_in" onClick={() => trackHeaderClick('Log In')}>
      Log in
    </a>
  );

  return (
    <div className={cx(styles.loginLink, { [styles.hide]: isReturningGuest }, className)} role="navigation">
      {isReturningGuest ? <span>Welcome back!</span> : loginElement}
    </div>
  );
};

LoginLink.propTypes = {
  className: PropTypes.string,
  isReturningGuest: PropTypes.bool,
  isButton: PropTypes.bool
};

LoginLink.defaultProps = {
  className: '',
  isReturningGuest: false,
  isButton: false
};

export default LoginLink;
