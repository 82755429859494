import React from 'react';
import Button from 'components/common/Button';
import PropTypes from 'prop-types';

import { trackHeaderClick } from 'shared/utils/tracking-utils/header';

const SignUpButton = (props) => {
  const onSignUpClick = () => {
    window.location = '/pricing';
    trackHeaderClick('Sign Up');
  };

  return (
    <div className={props.className}>
      <Button
        color="orange"
        size="small"
        text="sign up"
        onClick={onSignUpClick}
        styles={{ height: '40px', fontSize: '14px', fontWeight: 500 }}
        {...props}
      />
    </div>
  );
};

SignUpButton.propTypes = {
  className: PropTypes.string
};

SignUpButton.defaultProps = {
  className: ''
};

export default SignUpButton;
