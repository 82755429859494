import axios from 'axios';

const getBlogData = async () => {
  const blogData = await axios.get(`https://blog.blueapron.com/feed/`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin'
    }
  });

  const blogResponseDoc = new DOMParser().parseFromString(blogData.data, 'application/xml');
  return blogResponseDoc;
};
export default getBlogData;
