import Cookies from 'js-cookie';

import store from 'redux/store';

import { showError, showInfo, showSuccess, LEVELS } from 'redux/reducers/notices';

import { KEY_MESSAGE_MAP } from 'services/notices/messages';

const dispatchShowNotice = (key, messageInfo) => {
  const { message, level, displayMethod } = messageInfo;

  switch (level) {
    case LEVELS.INFO: {
      return store.dispatch(showInfo(key, message, displayMethod));
    }
    case LEVELS.SUCCESS: {
      return store.dispatch(showSuccess(key, message, displayMethod));
    }
    case LEVELS.ERROR: {
      return store.dispatch(showError(key, message, displayMethod));
    }
    default: {
      return store.dispatch(showInfo(key, message, displayMethod));
    }
  }
};

const checkCookies = () => {
  Object.entries(KEY_MESSAGE_MAP).forEach((keyMessage) => {
    const [key, messageInfo] = keyMessage;
    if (Cookies.get(key)) {
      if (!messageInfo.ensureCondition || messageInfo.ensureCondition()) {
        dispatchShowNotice(key, messageInfo);
        Cookies.remove(key, { path: '/' });
      }
    }
  });
};

export const initializeNotices = () => {
  checkCookies();
};

export default { initializeNotices };
