import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const DIRECTION_MAP = {
  left: 'rotate(90deg)',
  right: 'rotate(-90deg)',
  up: 'rotate(180deg)',
  down: 'rotate(0deg)'
};

const Caret = (props) => {
  const largeStyle = props.large ? ' scale(1.3)' : '';

  return (
    <Icon
      height={props.height}
      svgStyles={{
        transform: `${DIRECTION_MAP[props.direction]}${largeStyle}`
      }}
      containerClassNames={props.containerClassNames}
      width={props.width}
      viewBox={props.viewBox}
    >
      <path
        d="M1087 42c-.3 0-.5 0-.7-.3l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 3.3-3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3"
        fill="currentColor"
      />
    </Icon>
  );
};

const { oneOf, string } = PropTypes;

Caret.propTypes = {
  containerClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)]),
  direction: oneOf(['left', 'right', 'up', 'down']),
  height: string,
  viewBox: string,
  width: string,
  large: PropTypes.bool
};

Caret.defaultProps = {
  containerClassNames: undefined,
  direction: 'down',
  height: '7',
  viewBox: '1081 35 12 7',
  width: '12',
  large: false
};

export default Caret;
